import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

import "./index.css";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import "./lib/axios";

import Root from "./routes/root";
import ErrorPage from "./error-page";
import Home from "./routes/home";
import Category from "./routes/category";
import Product from "./routes/product";
import Shop from "./routes/shop";
import Account from "./routes/account";
import EditAccount from "./components/account/edit-account";
import ChangePassword from "./components/account/change-password";
import Favourite from "./components/account/favourite";
import ChangeLanguage from "./components/account/change-language";
import Cart from "./routes/cart";
import RetiredMilitary from "./components/account/retired-military";
import SpecialCustomer from "./components/account/special-customer";
import ShareApp from "./components/account/share-app";
import MyPoints from "./components/account/my-points";
import Orders from "./components/account/orders";
import TermsConditions from "./routes/terms-conditions";
import Offers from "./routes/offers";
import RegisterStore from "./routes/register-store";
import Addresses from "./components/account/addresses";
import AddNewAddress from "./components/account/add-new-address";
import Search from "./routes/search";
import CardContent from "./components/checkout/checkout-gifts/card-content";
import CheckoutSingle from "./routes/checkout-single";
import CheckoutMulti from "./routes/checkout-multi";
import CheckoutGifts from "./routes/checkout-gifts";
import CheckoutSacrifices from "./routes/checkout-sacrifices";
import CheckoutDonations from "./routes/checkout-donations";
import Notifications from "./routes/Notifications";
import Wallet from "./routes/wallet";
import ComingSoon from "./components/comingsoon/comingsoon";
import HeaderLogo from "./assets/header-logo";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            { path: "/", element: <ComingSoon /> },
            { path: "home", element: <Home /> },
            { path: "notifications", element: <Notifications /> },
            { path: "search", element: <Search /> },
            { path: "category/:categoryId", element: <Category /> },
            { path: "product/:productId", element: <Product /> },
            { path: "shop/:shopId", element: <Shop /> },
            { path: "cart", element: <Cart /> },
            { path: "change-password", element: <ChangePassword /> },
            {
                path: "account",
                element: <Account />,
                children: [
                    { path: "edit-profile", element: <EditAccount /> },
                    { path: "", element: <EditAccount /> },
                    { path: "favourite", element: <Favourite /> },
                    { path: "change-language", element: <ChangeLanguage /> },
                    { path: "retired-military", element: <RetiredMilitary /> },
                    { path: "share-app", element: <ShareApp /> },
                    { path: "orders", element: <Orders /> },
                    { path: "my-points", element: <MyPoints /> },
                    { path: "special-customer", element: <SpecialCustomer /> },
                    { path: "addresses", element: <Addresses /> },
                    { path: "add-new-address", element: <AddNewAddress /> },
                ],
            },
            { path: "offers", element: <Offers /> },
            { path: "register-store", element: <RegisterStore /> },
            { path: "terms-conditions", element: <TermsConditions /> },
            { path: "add-new-address", element: <AddNewAddress /> },
            {
                path: "checkout/gifts/:cart_id",
                element: <CheckoutGifts />,
            },
            {
                path: "checkout/gifts/card-content/:cart_id",
                element: <CardContent />,
            },
            {
                path: "checkout/sacrifices",
                element: <CheckoutSacrifices />,
            },
            {
                path: "checkout/donations",
                element: <CheckoutDonations />,
            },
            {
                path: "checkout/single",
                element: <CheckoutSingle />,
            },
            {
                path: "checkout/multi",
                element: <CheckoutMulti />,
            },
            {
                path: "wallet",
                element: <Wallet />,
            },

            { path: "*", element: <ErrorPage /> },
        ],
    },
]);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} /> 
        <ComingSoon/>
        <ToastContainer
            position="bottom-right"
            className="relative z-[99999] !w-auto text-nowrap"
        />
    </QueryClientProvider>,
);
